import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { getAuthToken } from "../../../../core/auth";
import {
  usersActions,
  getUsersSearching,
  getDomainSearching,
} from "../../../../core/users";
import SearchField from "../../forms/search-field";

const getSearchDomain = (location) => {
  const query = location.search;
  if (!query) {
    return null;
  }
  const params = new URLSearchParams(location.search);
  return params.get("domain");
};

function UserSearch (props) {
  let { userIdOrEmail } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState();

  const { searchUsers, resetUserState, searchByDomain } = props;

  useEffect(() => {
    if (!userIdOrEmail) {
      resetUserState();
    } else if(userIdOrEmail !== searchTerm) {
      setSearchTerm(userIdOrEmail);
      searchUsers({ searchTerm: userIdOrEmail });
    }
    const domainSearch = getSearchDomain(location);
    if(domainSearch) {
      searchByDomain({ searchTerm: domainSearch, newSearch: true });
    }

  }, [userIdOrEmail, resetUserState, searchTerm, searchUsers, searchByDomain, location]);

  const handleSearch = searchTerm => {
    let trimmedSearch = searchTerm.trim();
    if (!trimmedSearch.length || trimmedSearch.length < 2) {
      return;
    }

    if (trimmedSearch[0] === "@") {
      navigate(`/users/?domain=${trimmedSearch}`);
    } else {
      navigate(`/users/${trimmedSearch}`);
    }
  };

  const { authToken } = props;

  return (
    <SearchField
      authToken={authToken}
      onSubmit={handleSearch}
      search={userIdOrEmail || getSearchDomain(location)}
      placeholder="Search users by email or ID"
    />
  );
}

const mapStateToProps = createSelector(
  getAuthToken,
  getUsersSearching,
  getDomainSearching,
  (authToken, usersSearching, domainSearching) => ({
    authToken,
    usersSearching,
    domainSearching,
  })
);

const mapDispatchToProps = {
  ...usersActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearch);
